import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/maistho/prog/gustavbylund.se/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`404`}</h2>
    <h1>{`Page Not Found`}</h1>
    <p>{`The specified page was not found. If you have arrived here by clicking a link, please `}<a parentName="p" {...{
        "href": "mailto:gustav@kada.se?subject=Broken%20Link"
      }}>{`let me know`}</a>{` and I’ll try to fix it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      